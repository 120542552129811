<template>
  <div class="warehouse">
    <div class="tc">
      <van-icon class="fz icon_m" name="checked" />
      <div class="font-title">{{actType == 'edit'?"修改":"下单"}}成功</div>
      <div class="font-msg">{{title}}</div>
      <div class="fz26 tc vw" style="color:#A59C99;margin-top:45rpx;width:460rpx">一站网客服将在30分钟内，
        与您核对订单信息，请您保持通讯畅通。</div>
      <van-button class="jumpBtn" round plain :disabled="jumpDisabled" @click="jumpFun">我知道了</van-button>
    </div>
  </div>
</template>
<script>

  export default {
    name: 'finished',
    data() {
      return {
        actType: false,//false:新建 true:修改
        title: '',//标题
        jumpDisabled: false,
      };
    },
    mounted() {
      this.actType = this.$route.params.actType || ''
      this.title = this.$route.params.title || ''
      this.type = this.$route.params.type || ''
    },
    methods: {
      jumpFun() {
        this.$router.push({ name: 'm_order', params: { type: this.type } });
      }
    }
  };
</script>
<style scoped>
  .warehouse {
    width: 100%;
    min-height: 100vh
  }

  .fz {
    margin-top: 25vh;
    font-size: 150px;
  }

  .font-title {
    margin-top: 45px;
    font-size: 38px;
    color: #666;
    font-weight: 500;
  }

  .font-msg {
    margin-top: 13px;
    font-size: 26px;
    color: #666;
  }

  .vw {
    display: inline-block;
    margin-top: 30px;
    padding: 0 20vw;
  }

  .jumpBtn {
    margin-top: 20vh;
    padding: 0 100px;
    background-color: transparent;
    border-color: #007EC5;
    color: #007EC5
  }
</style>